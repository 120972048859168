import { Action } from '@pretto/website/src/templates/blocs/rows/secondaryMenu/components/NextTab'
import { Tab } from '@pretto/website/src/templates/blocs/rows/secondaryMenu/components/Tab'

import React, { useEffect, useRef } from 'react'

import * as S from './SecondaryMenu.styles'
import { useKeyboardNavigation } from './lib/useKeyboardNavigation'
import { useTabsDisplay } from './lib/useTabsDisplay'

const SCROLLING_OPTIONS: ScrollIntoViewOptions = {
  behavior: 'auto',
  block: 'end',
  inline: 'end',
}

export interface Item {
  href: string
  id: string
  label: string
  isSelected: boolean
}

export interface SecondaryMenuProps {
  color: string
  items: Item[]
}

export const SecondaryMenu: React.FC<SecondaryMenuProps> = ({ color = 'white', items, ...props }) => {
  const tabsRef = useRef<HTMLAnchorElement[]>([])

  const { changeFocus, focusIndex } = useKeyboardNavigation(tabsRef)
  const { isPrevNextdisplayed, firstTabsNotInViewPort, formatedItems, handleScroll } = useTabsDisplay(tabsRef, items)

  useEffect(() => {
    tabsRef.current[focusIndex ?? 0].scrollIntoView(SCROLLING_OPTIONS)
  }, [focusIndex])

  const handleClick = (event: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>) => {
    tabsRef.current[firstTabsNotInViewPort[event.currentTarget.getAttribute('action') as Action]].scrollIntoView(
      SCROLLING_OPTIONS
    )
  }

  const nextTabProps = {
    color,
    onClick: handleClick,
    tabIndex: -1,
  }

  return (
    <S.NewBlocRow $color={color} {...props}>
      <S.SecondaryMenuContainer>
        <S.ScrollableContainer
          id="menubar"
          role="menubar"
          aria-labelledby="menubar"
          ria-orientation="vertical"
          onScroll={handleScroll}
        >
          {formatedItems.map(({ id, ...item }, i) => {
            const handleKeyDown = (event: React.KeyboardEvent<HTMLAnchorElement>) => {
              changeFocus(event)
            }

            const handleRef = (tab: HTMLAnchorElement) => {
              if (tab !== null) {
                tabsRef.current[i] = tab
              }
            }

            return <Tab tabIndex={i} role="menuitem" ref={handleRef} key={id} onKeyDown={handleKeyDown} {...item} />
          })}
        </S.ScrollableContainer>

        <S.PrevNextContainer>
          <S.NextTab $display={isPrevNextdisplayed.previous} action="previous" {...nextTabProps} />
          <S.NextTab $display={isPrevNextdisplayed.next} action="next" {...nextTabProps} />
        </S.PrevNextContainer>
      </S.SecondaryMenuContainer>
    </S.NewBlocRow>
  )
}
